import useWindowDimensions from './hooks/useWindowDimensions';

import logo from './adaptive-icon.png';

import PDEmblem from './assets/emblems/PD.png';
import PLEmblem from './assets/emblems/PL.png';
import BLEmblem from './assets/emblems/BL1.png';
import ECEmblem from './assets/emblems/EC.png';
import WCEmblem from './assets/emblems/WC.png';
import CLEmblem from './assets/emblems/CL.png';

import SAEmblem from './assets/emblems/SA.png';
import FLEmblem from './assets/emblems/FL1.png';
import ELCEmblem from './assets/emblems/ELC.png';
import DEDEmblem from './assets/emblems/DED.png';
import PPLEmblem from './assets/emblems/PPL.png';
import BSAEmblem from './assets/emblems/BSA.png';

import GooglePlayBadge from './assets/google-play-badge.png';
import AppleStoreBadge from './assets/app_Store_badge.png';

import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1
  },
}))

function App() {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const isMobile = width <= 768
  
  const storeDimensions = isMobile ? {width: 160, height: 48} : {width: 200, height: 60}
  const paperDimensions = isMobile ? {width: width * 9 / 10} : {width: width * 2 / 3}

  return (
      <Box flex={1}>
          <header className="App-header">
              <img src={logo} className="App-logo" alt="logo" />
              <div style={{ paddingTop: 30, flexDirection: "row", justifyContent: "space-around" }}>
                  <Link href="https://play.google.com/store/apps/details?id=com.tiko">
                      <img src={GooglePlayBadge} alt="GP" style={{ ...storeDimensions, paddingRight: 30 }} />
                  </Link>
                  <Link href="https://apps.apple.com/us/app/tiko/id1568156078#?platform=iphone">
                      <img src={AppleStoreBadge} alt="AP" style={storeDimensions} />
                  </Link>
              </div>
              <p>
                  Tiko is a social multi-platform soccer prediction application designed to replace the old-school excel
                  scoring management.
                  <br />
                  Our agenda – say no to <b>SPREADSHEETS!</b>
              </p>
          </header>

          <div className="App-content">
              <Paper style={{ justifyContent: "space-between", width: paperDimensions.width, flex: 1 }}>
                  <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, fontSize: 18 }}>
                      <div>
                          Tiko enhances the user experience and reduces <b>every aspect</b> of manual effort!
                          <ul>
                              <li>Get more comprehensive control over the gaming experience.</li>
                              <li>
                                  Tune scoring by following the matches' odds or fully customize awards points for each
                                  competition’s stage.
                              </li>
                          </ul>
                          All data is <b>automatically</b> analyzed and displayed, including standings, statistics,{" "}
                          <b>scoring tables</b>, and charts.
                          <br />
                          Matches' odds are collected and updated regularly from top-tier betting sites.
                          <br />
                          We keep members on track by notifying them of leaderboard changes and upcoming games not to
                          miss any predictions.
                          <br />
                      </div>
                      <Divider style={{ marginTop: 20, marginRight: 30, marginLeft: 30 }} />
                      <p style={{ paddingTop: 10, textAlign: "center" }}>
                          We support <b>12</b> top-notch competitions
                      </p>
                      <Box>
                          <Grid container className={classes.grid} spacing={2}>
                              <Grid item xs={12}>
                                  <Grid container justify="center" spacing={3}>
                                      <Grid key={0} item>
                                          <img src={PDEmblem} alt="PD" className="Competition-emblem" />
                                      </Grid>
                                      <Grid key={1} item>
                                          <img src={PLEmblem} alt="PL" className="Competition-emblem" />
                                      </Grid>
                                      <Grid key={2} item>
                                          <img src={BLEmblem} alt="BL" className="Competition-emblem" />
                                      </Grid>
                                      <Grid key={3} item>
                                          <img src={CLEmblem} alt="CL" className="Competition-emblem" />
                                      </Grid>
                                      <Grid key={4} item>
                                          <img src={ECEmblem} alt="EC" className="Competition-emblem" />
                                      </Grid>
                                      <Grid key={5} item>
                                          <img src={WCEmblem} alt="WC" className="Competition-emblem" />
                                      </Grid>
                                  </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                  <Grid container justify="center" spacing={3}>
                                      <Grid key={0} item>
                                          <img src={SAEmblem} alt="SA" className="Competition-emblem" />
                                      </Grid>
                                      <Grid key={1} item>
                                          <img src={FLEmblem} alt="FL" className="Competition-emblem" />
                                      </Grid>
                                      <Grid key={2} item>
                                          <img src={ELCEmblem} alt="ELC" className="Competition-emblem" />
                                      </Grid>
                                      <Grid key={3} item>
                                          <img src={DEDEmblem} alt="DED" className="Competition-emblem" />
                                      </Grid>
                                      <Grid key={4} item>
                                          <img src={PPLEmblem} alt="PPL" className="Competition-emblem" />
                                      </Grid>
                                      <Grid key={5} item>
                                          <img src={BSAEmblem} alt="BSA" className="Competition-emblem" />
                                      </Grid>
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Box>
                      <Divider style={{ marginTop: 20, marginRight: 30, marginLeft: 30 }} />
                      <br />
                      <div>
                          <b style={{ fontSize: 22 }}>How To Join?</b>
                          <br />
                          Download the app from Google Play or the Apple Store.
                          <br />
                          Sign in with your Facebook / Google account or with an email & password combination.
                      </div>
                      <div style={{ paddingTop: 20 }}>
                          <b style={{ fontSize: 22 }}>Groups</b>
                          <br />
                          Member must be a part of a group to place his scoring predictions.
                          <br />
                          Group admins have complete control over competition subscriptions, scoring methods, group
                          members, and user join requests.
                      </div>
                      <p>Tiko allows two types of groups:</p>
                      <p>
                          <b style={{ fontSize: 18 }}>Private Groups</b>
                          <br />
                          Shown publicly, members can join by entering the group's code.
                          <br />
                          Group administrators are responsible for inviting users and sharing the group's code.
                      </p>
                      <p>
                          <b style={{ fontSize: 18 }}>Public Groups</b>
                          <br />
                          Anyone can join the group.
                      </p>
                      <p>
                          <b style={{ fontSize: 22 }}>Competition</b>
                          <br />
                          Predictions may concern either the matches' regular game time (90 minutes plus stoppage time)
                          or extra time.
                          <br />
                          All predictions become visible for all group members following the kick-off of each match.
                      </p>
                      <p>
                          <b>Best of luck!</b>
                      </p>
                  </div>
              </Paper>
              <Link href="/privacy-policy" style={{ marginTop: 10 }}>
                  <b style={{ fontSize: 16, color: "white" }}>Privacy Policy</b>
              </Link>
              <b style={{ fontSize: 16, color: "white" }}>With ❤️ by TAPPS</b>
          </div>
      </Box>
  );
}

export default App;
